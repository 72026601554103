.circular-image-small {
  height: 36px;
  width: 36px;
  border-radius: 50%;
}
.circular-image-small1 {
  width: 25.59px;
  height: 25.79px;
  border-radius: 50%;
}
.chart-container {
  position: relative;
 // margin: auto;
  height: 35vh;
 // width: 40vw;
}