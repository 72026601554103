.images{
    margin-left: 67px;
    height: 48px;
    width: 48px;
    margin-top: 15px;
    }
    .full-loader-wrapper {
      max-width: 600px;
      width: 400px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white
      }
    .paperdiv{
        margin-left: 55px;
        padding-top: 55px;
    
    }
    .img{
        filter: invert(.5) sepia(1) saturate(5) hue-rotate(175deg);
    }
    .h1over{
        font-family: Source Sans Pro,sans-serif;
        font-size: 30px;
        line-height: 1.5;
        color: #595b5d;
        font-weight: 300;
        //text-align: center;
        margin-left: 18%;
        margin-top: -28px;
    }
    .pover{
        //text-align: center;
        margin-left: 26%;
        font-size: 16px;
        margin-top: -14px;
    }
    .head{
        font-weight: bold;
font-size: 25px;
color: rgba(0,64,135,1);

text-align: center;
    }
    .subhader{
        
font-size: 14px;
color: rgba(97,119,144,1);
margin-top: -20px;
text-align: center;
    }
    .head1{
       
        font-size: 17px;
color: #617790;
letter-spacing: 0;
//margin-left: 40px;
//margin-top: 63px;
    }
    .imgallin{
        margin: auto;
display: block;
max-width: 100%;
max-height: 100%;
margin-top: 12px;

    }
    .matter{
        margin-top: -3px;
text-align: center;
font-size: 12px;
color: #004087;
letter-spacing: 0;
    }
    .mattersub{
        font-size: 11px;
color: #617790;
letter-spacing: 0;


//display: block;
max-width: 100%;
max-height: 100%;


    }
    .matter1{
        margin-top: -3px;

font-size: 12px;
color: #004087;
letter-spacing: 0;
    }
    .imgallin1{
        margin: auto;
display: block;
max-width: 100%;
max-height: 100%;
margin-top: 14px;
margin-left: 2px;

    }

    .third-party-toolsss {
       // padding: 10px 0;
        text-align: center;
      
        .tool-card {
          width: 150px;
          height: 90px;
          overflow: hidden;
          cursor: pointer;
          display: inline-block;
         // margin: 24px 32px 0 0;
      
          &:hover {
            .card-title {
              bottom: 60%;
              transition: all .5s;
            }
          }
      
          & > div {
            height: 100%;
            overflow: hidden;
            position: relative;
      
            & > div {
              height: 100%;
              overflow: hidden;
            }
      
            .card-tit {
              bottom: 20%;
              height: 60%;
              color: white;
              transition: all .5s;
      
              h3 {
                margin-top: 0;
              }
            }
      
            .media-wrapper {
              & > div {
                height: 100%;
                text-align: center;
      
                .tool-icon {
                  min-width: initial !important;
                  width: auto !important;
                  height: 100% !important;
                }
              }
            }
          }
      
          .card-title {
           // bottom: 48px;
            height: 60%;
            color: white;
            transition: all .5s;
            padding: 10px;
      
            h3 {
              margin-top: 0;
              text-align: center;
              font-size: 15px;
              padding-top: 2px;
            }
          }
      
          .text {
            padding: 5px;
            box-sizing: border-box;
            overflow: hidden;
          }
      
          .media-wrapper {
            text-align: center;
            background-size: contain;
            transform: scale(.8);
      
            &.larger {
              transform: scale(1.2);
            }
      
            .tool-icon {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
      .feedback-button {
        position: fixed;
        bottom: 25px;
        right: 25px;
        z-index: 10000;
       // background-color: '#036bfcb';
      }