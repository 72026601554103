.create-persona-dialog {
    padding: 0 !important;
    border-bottom: 1px solid whitesmoke !important;
    overflow-y: auto !important;
  }
  .filters {
    @media (max-width: 1289px) {
      max-width: 100%;
    }
    & > div {
      display: inline-block;
      margin-right: 16px;
    }
    .chip {
      min-width: 140px;
      &.active {
        & > svg {
          fill: #BCDBF0 !important;
        }
      }
      & > svg {
        position: absolute;
        right: 0;
      }
      & > span {
        display: inline-block;
        width: 100%;
       // position: relative;
        .icon-wrapper {
          vertical-align: middle;
          float: right;
          position: absolute;
          right: 5px;
          top: 4px;
          svg {
            vertical-align: middle;
          }
        }
      }
      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
        display: block;
      }
    }
  }
  
  .filter-title {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    h3 {
      margin: 0;
      padding-left: 16px !important;
      font-size: 20px;
    }
  }
  
  .age-filter-wrapper {
    width: 300px;
    height: 120px;
    padding: 16px;
    padding-top: 40px;
    & > div {
      & > * {
        vertical-align: middle;
        padding: 0 7px;
      }
      & > span:first-of-type {
        display: inline-block;
        width: 36px;
      }
    }
    .slider {
     // display: inline-block;
    //  min-width: 180px;
      & > div {
        & > div > div {
          &:first-of-type, &:last-of-type {
            background-color: #0E5676 !important;
            border-color: #0E5676 !important;
            border: none !important;
            & > div > div > div {
              background-color: #0E5676 !important;
              border-color: #0E5676 !important;
            }
          }
          &:nth-of-type(2) {
            background-color: rgb(117, 117, 117) !important;
          }
        }
        margin-bottom: 20px !important;
      }
    }
    .age-filter-value {
      width: 20px !important;
      hr {
        width: 20px !important;
      }
    }
  }
  
  .persona-type-filter-menu {
    .fa:before {
      position: relative;
      left: 3px;
      top: 5px;
    }
  }
  .persona-inputs-wrapper {
    .paper-body {
      padding-top: 20px;
    }
    .persona-info-row {
      height: 35px;
      &.high {
        height: 55px;
        & > *:first-child {
          line-height: 55px;
        }
        & > *:nth-child(2) {
          line-height: 55px;
        }
      }
      & > *:first-child {
        display: inline-block;
        width: 25%;
        line-height: 35px;
        float: left;
      }
      & > *:nth-child(2) {
        display: inline-block !important;
        width: 75% !important;
        line-height: 35px;
        & .additional-info {
          font-size: 0.8rem;
          position: relative;
          top: -2rem;
        }
      }
    }
  }
  .personas-wrapper {
    position: relative;
    &.modal {
      .filter-wrapper {
        .filters {
          max-width: calc(100% - 250px);
        }
        .actions {
          position: absolute;
          top: 0;
          right: 20px;
        }
      }
    }
    & > div:nth-child(2) {
      //padding-bottom: 40px;
    }
    .filter-wrapper {
      margin: 16px 0;
      & > * {
        display: inline-block;
        vertical-align: middle;
        &:not(.actions) {
          margin-right: 15px;
        }
        &.actions {
          float: right;
          position: relative;
          top: -25px;
          & > * {
            float: left;
          }
        }
      }
    }
    .persona-table-wrapper {
      padding-bottom: 30px;
      & > div {
        box-shadow: 0 -1px 0 #e0e0e0, 0 0 2px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .24);
      }
      &.modal {
        & > div {
          width: 100%;
        }
        .persona-list-item:hover {
          cursor: pointer;
        }
      }
      td.name, th.name {
        width: 20% !important;
      }
      td.resource, th.resource {
        width: 28% !important;
      }
      td.persona-info {
        &.login {
          & > * {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
      td.pract, th.pract {
       // width: 47% !important;
      }
      td:first-of-type, th:first-of-type {
        width: 24px !important;
      }
      &:not(.modal) {
        td:not(.actions-row):last-of-type, th:not(.actions-row):last-of-type {
          //width: 44px !important;
        }
        td.actions-row:last-of-type, th.actions-row:last-of-type {
          width: 125px !important;
          &.small {
            width: 40px !important;
          }
        }
      }
      .persona-table-header {
        tr {
         // height: 32px !important;
          th {
          //  height: 32px !important;
          }
        }
      }
      .persona-list-item {
        transition: all .3s;
        transition-delay: .3s;
        &.active {
          border-bottom: none !important;
          transition: all .3s;
          .actions-row {
            button:last-of-type {
              transform: scaleY(-1);
            }
          }
        }
      }
      .patient {
        tr:not(.content):hover {
          cursor: pointer;
        }
      }
      tr:not(.content):hover {
        td {
          background-color: #F5F5F5 !important;
        }
      }
      tr.content {
        height: 0 !important;
        position: relative;
        top: -5px;
        margin: 0;
        padding: 0;
        border: none !important;
        transition: all .3s;
        overflow: hidden !important;
        & > td {
          height: 0 !important;
          margin: 0;
          padding: 0;
          transition: all .3s;
          .chart {
            height: 0;
            transition: all .3s;
            overflow: hidden;
          }
        }
        &.active {
          height: 300px !important;
          transition: all .3s;
          overflow: hidden !important;
          border-bottom: 1px solid rgb(224, 224, 224) !important;
          & > td {
            transition: all .3s;
            height: 300px !important;
            overflow: hidden !important;
            .chart {
              height: 300px;
              transition: all .3s;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  
  .modal {
    .persona-list-pagination-wrapper {
      &.bottom {
        left: -20px;
      }
    }
  }
  
  .persona-list-pagination-wrapper {
    text-align: right !important;
    position: relative;
    top: 42px;
    &.pract {
      top: 16px;
    }
    &.bottom {
      top: 20px;
      left: -55px;
    }
    & > * {
      button {
        height: 22px !important;
        padding: 0 !important;
        position: relative;
        top: 2px;
      }
      span {
        font-size: 12px;
        line-height: 20px;
      }
      display: inline-block;
      vertical-align: middle;
    }
  }

  .patients-wrapper {
    height: calc(100% - 70px);
    position: relative;
    & > div {
      height: 100%;
      .paper-card {
        height: 100%;
        .paper-body {
          height: 100%;
          overflow: hidden;
          & > div {
            &:first-of-type:not(.hidden) {
              height: 6% !important;
              overflow: hidden !important;
            }
            &:nth-of-type(2) {
              height: 94% !important;
              overflow: hidden !important;
              & > div:nth-child(2) {
                height: calc(100% - 120px) !important;
              }
            }
          }
        }
      }
    }
    .centered {
      width: 100%;
      text-align: center;
      vertical-align: middle;
      padding-top: 200px;
    }
    .actions {
      position: absolute;
      right: 10px;
      top: 1px;
      z-index: 1000;
      .custom-button {
        margin-right: 20px;
        & > button span {
          margin-right: 20px !important;
        }
        .fa {
          margin-left: 30px !important;
          font-size: 1.2rem;
        }
      }
      h4 {
        display: inline-block;
        margin: 1rem;
      }
    }
  }
  
  .persona-info-dialog {
    & > div > div:first-of-type {
      padding: 0 !important;
    }
  }
  