.error-message-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    z-index: 10000;
    .custom-snack {
      display: inline-block;
      max-width: 60vw;
      padding: .7rem 4rem;
      position: relative;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      max-height: 150px;
      overflow: auto;
      .error-close-button {
        position: absolute !important;
        right: 0;
        top: -3px;
      }
    }
  }