.delete-wrapper {
   // display: inline-block;
    overflow: hidden;
    vertical-align: top;
    color: initial;
    //padding-top: 20px;
    h4 {
      width: 560px;
      background-color: rgb(232, 232, 232);
      padding: 20px;
      margin-top: 0;
      margin-bottom: 0;
      color: #4a525d;
    }
    .button {
      margin: 16px 12px 0 0;
    }
    .delete-content {
      padding-top: 24px;
      margin: 0 !important;
    }
  }
  .paper-card.delete-dialog {
    h3 {
      font-size: 2rem;
      background-color: #f82219;
      color: white;
    }
  }
  .delete-sandbox-dialog {
    .cancel-button {
      margin-left: 1rem;
    }
  }
  
  .delete-sandbox-confirm-dialog {
    //padding: 20px !important;
    box-sizing: border-box;
    border-bottom: 1px solid whitesmoke;
    & > * {
      box-sizing: border-box;
      overflow: hidden;
    }
  }
  .sandbox-details-wrapper {
    min-width: 400px;
    width: 600px;
    margin: 10px 20px;
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
  
    h4 {
      width: 610px;
      background-color: rgb(232, 232, 232);
      padding: 20px;
      margin-top: 0;
      color: #4a525d
    }
  
    form {
     // padding: 0 20px 20px 20px;
  
      input[disabled] {
        color: rgba(0, 0, 0, 0.7) !important;
      }
    }
  
    .details-button {
      margin: 12px 12px 0 0;
    }
  
    .header-actions-wrapper {
      text-align: right !important;
      padding-right: 10px;
      background-color: whitesmoke;
    }
  
    & > div:last-child {
      padding: 16px;
    }
  
    .label-value {
      margin-bottom: 10px;
    }
  }
  
  .modal-bottom-actions-wrapper {
    text-align: left;
    padding-left: 20px;
  }
  
  .sandbox-edit-modal {
    //padding-top: 80px;
  
    & > *:not(.screen-title) {
      margin-bottom: 20px;
    }
  }
  
  .settings-dialog {
    //padding: 30px 30px 0 30px;
    padding: 0px;
  }
  .reset-wrapper {
    color: initial;
    //padding-top: 20px;
    .reset-content {
      padding: 24px 0 0 0;
      margin: 0 !important;
    }
  }
  
  .paper-card.reset-dialog {
    h3 {
      font-size: 2rem;
      background-color: #f82219;
      color: white;
    }
  }
  
  .reset-sandbox-dialog {
   // padding: 20px !important;
    box-sizing: border-box;
    border-bottom: 1px solid whitesmoke;
    & > * {
      box-sizing: border-box;
      overflow: hidden;
    }
  }
  
  .app-dialog {
    .cancel-button {
      margin-left: 1rem;
    }
  }
  