.layout2 {
  .topbar {
    position: relative;
    width: 100%;
    display: table;
    height: $topbar-height;
    border-bottom: 1px solid transparent;
    padding-top: 1rem;
    padding-bottom: 1rem;
    z-index: 98;
    .brand {
      height: 100%;
      img {
        height: 32px;
      }
      .brand__text {
        font-weight: 500;
        font-size: 1.5rem;
        margin: 0 1rem;
      }
    }

    .MuiIconButton-root {
      color: $white;
    }
  }
}
.aaa{
  cursor: pointer;
  display: flex;
  padding: 4px;
  align-items: center;
  border-radius: 10px;
}
.aaa:hover{
  background-color:	rgba(255, 255, 255, 0.15);
}