.profiles-wrapper {
	&.page-content-wrapper {
		text-align: center;
		.page-wrapper {
			display: inline-block;
			text-align: left;
			position: relative;
			height: 100%;
			.page-content-wrapper {
				height: calc(100% - 90px);
				box-sizing: border-box;
			}
		}
	}
}
.loaded-profiles-wrapper {
	height: 100%;
	position: relative;
	.profiles-list {
		background-color: #fff;
		margin-top: 16px;
		box-shadow: rgba(180,180,180,0.28) 0 1px 16px, rgba(180,180,180,0.28) 0 5px 24px !important;
		padding: 0;
		height: 100%;
		overflow: hidden;
		position: relative;
		.stepper {
			height: calc(100% - 64px);
			& > *:first-child {
				height: 30px;
				padding: 0 16px;
				background-color: whitesmoke;
				place-content: start !important;
				* {
					max-height: 30px !important;
					font-size: 1.1rem;
				}
			}
			& > *:nth-child(2) {
				height: calc(100% - 30px);
				overflow: hidden;
				border-top: 1px solid whitesmoke;
				position: relative;
				.validate-button {
					position: absolute;
					right: 16px;
					top: 5px;
				}
			}
			.stepper-connector {
				display: inline-block;
				height: 15px;
				width: 15px;
				border-right: 5px solid darkgray;
				border-top: 5px solid darkgray;
				transform: scale(0.6) rotate(45deg);
				position: relative;
				flex: none;
				span {
					display: inline-block;
					height: 10px;
					width: 10px;
					border-right: 2px solid darkgray;
					border-top: 2px solid darkgray;
					position: relative;
					right: -2px;
					top: -2px;
				}
			}
			.react-json-view {
				height: calc(100% - 51px);
				border-top: 1px solid whitesmoke;
				overflow-y: auto;
			}
			.rc-tree {
				height: calc(100% - 60px);
				border-top: 1px solid whitesmoke;
				overflow: auto;
			}
		}
		.loader-wrapper {
			position: absolute;
			top: 0;
		}
		.wrapper {
			border-bottom: 1px solid whitesmoke;
			& > * {
				display: inline-block;
			}
		}
		.profiles {
			height: 100%;
			overflow-x: hidden;
			overflow-y: auto;
			box-sizing: border-box;
			padding: 0 !important;
			& > div {
				padding-left: 24px;
				& > span {
					padding: 0 16px !important;
				}
			}
			.profiles-filters {
				margin-left: 20px;
			}
			.avatar {
				background-color: whitesmoke;
				padding: 5px;
				border-radius: 20px;
				top: 10px !important;
			}
			.delete-button {
				color: gray;
				right: 10px !important;
				position: absolute;
			}
		}
		.nav {
			height: 64px;
			line-height: 64px;
			box-sizing: border-box;
			& > * {
				display: inline-block;
			}
			& > *:first-child {
				margin-right: 8px;
			}
		}
		.resource-tabs {
			& > *:nth-child(2) {
				border-bottom: 2px solid whitesmoke;
			}
		}
		.resource-info {
			padding: 10px;
			padding-left: 20px;
			height: calc(100% - 70px);
			overflow: auto;
			border-top: 1px solid whitesmoke;
			.label-value {
				display: inline-block;
				min-width: 25%;
				&.big {
					min-width: 100%;
				}
			}
			.rc-tree-node-selected {
				background-color: inherit;
				border: none;
				opacity: 1;
			}
			.rc-tree-node-content-wrapper {
				&.rc-tree-node-content-wrapper-normal {
					overflow: hidden;
					width: calc(80%);
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}
	}
}
.loader-wrapper-small {
	width: 180px;
	position: absolute;
	left: calc(50% - 85px);
	top: 30%;
	.info {
		display: block;
	}
}
.loading-results {
	width: 50vw !important;
	max-width: 50vw !important;
}
.profiles-loading-results-wrapper {
	margin-top: 68px;
	padding: 8px 16px;
	.resource-type {
		background-color: whitesmoke;
		padding: 5px 15px;
		cursor: pointer;
		position: relative;
		.chevron {
			position: absolute;
			right: 20px;
			transition: transform .3s;
			top: 4px;
			&.turn {
				transition: transform .3s;
				transform: rotate(180deg);
				top: -1px;
			}
		}
	}
	.resource-list {
		&.closed {
			height: 0;
			overflow: hidden;
		}
		& > * {
			margin: 5px 0;
		}
	}
	& > div {
		height: 35vh;
		overflow: hidden;
		margin-bottom: 5px;
		& > div {
			background-color: whitesmoke;
			padding: 5px 16px;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			border: 2px solid whitesmoke;
			&:nth-child(2) {
				padding-top: 3px;
				background-color: transparent;
				overflow: auto;
				height: calc(100% - 33px);
				box-sizing: border-box;
				border-radius: 0;
			}
		}
	}
}
.file-load-wrapper {
	position: absolute;
	right: 0;
	top: -65px;
}
.wrong-file-type-modal {
	width: 300px;
	height: 250px;
	.profiles-modal {
		height: 100%;
		& > div {
			&:nth-child(2) {
				height: calc(100% - 68px);
				padding-top: 100px;
				text-align: center;
				box-sizing: border-box;
			}
		}
	}
}
.resources-modal {
	& > div {
		& > div {
			&:first-of-type {
				padding: 0 !important;
				border-bottom: 2px solid whitesmoke !important;
			}
		}
	}
	.profiles-modal {
		height: 66vh;
		.resources {
			padding: 18px;
			width: 450px;
			& > * {
				cursor: pointer;
				line-height: 40px;
				margin-bottom: 10px;
				padding-left: 10px;
			}
			& > *.active {
				background-color: whitesmoke !important;
			}
		}
		.resource {
			border-right: 2px solid whitesmoke;
			width: 0;
			transition: all .5s;
			overflow: hidden;
			box-sizing: border-box;
			&.active {
				transition: all .5s;
				width: calc(100% - 450px);
				box-sizing: border-box;
			}
		}
	}
}
.resources-modal .profiles-modal .resources,
.resources-modal .profiles-modal .resource {
	display: inline-block;
	vertical-align: top;
	height: calc(100% - 68px);
	position: relative;
	top: 68px;
	margin-bottom: 68px;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
}
.project-input-modal {
	padding: 0 !important;
	max-width: initial;
	width: 600px;
	.client-details {
		padding: 10px;
		text-align: center;
		.chip {
			font-size: 1.1rem;
			display: inline-block !important;
			margin-right: 15px !important;
		}
		a {
			display: block;
			margin-top: 10px;
		}
		.buttons-wrapper {
			padding: 40px 0;
			text-align: center;
			& > * {
				margin: 0 .5rem;
				width: 180px;
			}
		}
	}
	.paper-card {
		box-shadow: none;
	}
}
.profiles-filters {
	height: 64px;
	& > div {
		display: inline-block;
		margin-right: 16px;
	}
	.chip {
		min-width: 140px;
		position: relative;
		top: 5px;
		&.active {
			& > svg {
				fill: #BCDBF0 !important;
			}
		}
		& > svg {
			position: absolute;
			right: 0;
		}
		& > span {
			&:first-of-type {
				display: inline-block;
				width: 100%;
				position: relative;
				.icon-wrapper {
					vertical-align: middle;
					float: right;
					position: absolute;
					right: 5px;
					top: -5px;
					svg {
						vertical-align: middle;
					}
				}
			}
		}
		.title {
			overflow: hidden;
			text-overflow: ellipsis;
			width: calc(80%);
			display: block;
		}
	}
}
.type-filter-menu {
	li {
		min-height: 40px !important;
	}
}
