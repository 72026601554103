.create-sandbox-wrapper {
	height: 100%;
	overflow: hidden;
	border-radius: 0 !important;
	color: black !important;
	.paper-card {
		height: 100%;
		overflow: hidden;
		border-radius: 0 !important;
		h3 {
			padding: 0 0 0 24px;
			height: 68px;
			line-height: 68px;
			font-size: 20px;
			background-color: #004087 !important;
		}
		.close-button {
			width: 68px !important;
			height: 68px !important;
		}
		.paper-body {
			height: calc(100% - 68px);
			overflow: auto;
			box-sizing: border-box;
			color: black !important;
			form {
				padding-bottom: 10px;
			}
			.checkboxes {
				& > * {
					margin: 0;
					color: black !important;
				}
			}
		}
	}
	.checkbox {
		margin-bottom: 16px;
	}
	.fhirVersion {
		div {
			div {
				padding-left: 0 !important;
				margin: -1px 24px -1px 0 !important;
			}
		}
	}
}
.create-sandbox-dialog {
	border-radius: 0 !important;
	height: 63vh;
	overflow: hidden;
	& > div {
		&:first-of-type {
			height: calc(100% - 52px);
			padding: 0 !important;
			margin: 0 !important;
			//border-bottom: 1px solid whitesmoke !important;
		}
		&:not(:first-of-type) {
			& > * {
				margin-right: 10px;
				margin-top: 0%;
			}
		}
	}
	.paper-card {
		border-radius: 0 !important;
	}
}
.stepper {
 // margin-top: 60px;
 // padding: 0 46px;
  .step-label {
  svg {
  color: #0E5676 !important;
  fill: #0E5676 !important;
  }
  }
  }
.create-scenario-content-wrapper {
	min-height: 300px;
	.persona-selection {
		text-align: center;
		padding-top: 10px;
		font-size: 18px;
		.personas-wrapper {
			&.modal {
				text-align: left;
			}
		}
		.persona-table-wrapper {
			&.modal {
				max-height: 30vh;
				overflow-y: auto;
			}
		}
		& > * {
			min-width: 200px;
			vertical-align: top;
			& > *:nth-child(2) {
				padding: 0 10px;
			}
		}
		& > div {
			& > span {
				font-size: 18px;
				padding-bottom: 16px;
				display: inline-block;
			}
		}
		.type-selection {
			margin-right: 35px;
			display: inline-block;
			text-align: left;
			font-size: 16px;
		}
		.selected-values {
			display: inline-block;
			text-align: left;
			margin-left: 35px;
			font-size: 16px;
			& > * {
				display: block;
				& > * {
					vertical-align: middle;
				}
			}
		}
	}
	.context-selection {
		& > *:not(.persona-list-wrapper) {
			display: inline-block;
			width: 50%;
			vertical-align: top;
			padding: 10px 32px;
			box-sizing: border-box;
			overflow: hidden;
		}
		.column-item-wrapper {
			&:not(.big-and-centered) {
				width: calc(100% - 40px);
				float: right;
				position: relative;
				& > svg {
					position: absolute;
					left: -40px;
					&:not(.no-vertical-align) {
						top: 36px;
					}
				}
				& > div {
					max-width: 90%;
				}
				.toggle {
					width: 220px !important;
					& > div {
						& > div {
							& > div {
								&:first-of-type {
									opacity: 0.5;
								}
							}
						}
					}
				}
			}
			&.big-and-centered {
				height: 120px;
				vertical-align: middle;
				text-align: center;
				float: right;
				width: 100%;
				padding-top: 50px;
				box-sizing: border-box;
				& > a {
					text-decoration: none;
					color: inherit;
					& > * {
						display: inline-block;
					}
				}
				svg {
					margin-right: 16px;
					position: relative;
					top: 6px;
				}
			}
		}
		.right-control {
			position: absolute;
			right: 20px;
			top: 26px;
			width: 48px;
			height: 48px;
			&.loader {
				right: 20px;
			}
		}
		.summary-item {
			span {
				&.section-title {
					font-size: 18px;
					color: black;
					font-weight: 500;
					display: inline-block;
					margin-bottom: 16px;
				}
				&.section-sub-title {
					font-size: 11px;
				}
			}
			.summary-item-icon-left {
				display: inline-block;
				width: 24px;
				height: 24px;
				margin-right: 16px;
				position: relative;
				top: 6px;
			}
			.summary-item-icon-right {
				display: inline-block;
				font-size: .5rem;
				margin-left: 16px;
			}
		}
	}
}




