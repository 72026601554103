.start-page-wrapper {
    width: auto;
    height: auto;
    overflow: hidden;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: calc(10% - 0px);
    & > *:nth-child(2) {
      margin: 25px 0;
    }
    .message {
      font-size: 12px;
      color: #0E5676;
      a:visited {
        color: inherit;
        opacity: .8;
      }
    }
  }
  