.filters {
    & > div {
      display: inline-block;
      margin-right: 16px;
    }
    .chip {
      min-width: 140px;
      &.active {
        & > svg {
          fill: #BCDBF0 !important;
        }
      }
      & > svg {
        position: absolute;
        right: 0;
      }
      & > span {
        display: inline-block;
        width: 100%;
       // position: relative;
        .icon-wrapper {
          vertical-align: middle;
          float: right;
          position: absolute;
          right: 5px;
          top: 5px;
          svg {
            vertical-align: middle;
          }
        }
      }
      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 20px);
        display: block;
      }
    }
  }
  

  .launch-scenario-dialog {
    .selected-text {
      position: absolute;
      right: 0;
      padding-top: 20px;
    }
    .subscript.right {
      position: absolute;
      right: 35px;
      bottom: -2px;
    }
    .modal-title {
      position: absolute;
      display: inline-block;
      width: 100%;
      margin: 0;
      padding-left: 24px;
      height: 68px;
      line-height: 68px;
      vertical-align: middle;
      font-size: 20px;
      box-sizing: border-box;
    }
    .close-button {
      height: 68px !important;
      width: 68px !important;
      position: absolute !important;
      right: 0;
    }
    .persona-list-wrapper {
      height: 0;
      overflow: hidden;
      z-index: 1000;
      background: #fff;
      width: 100%;
      position: absolute;
      bottom: 0;
      transition: all .5s;
      &.active {
        height: 100%;
        transition: all .5s;
      }
    }
    .stepper {
      margin-top: 60px;
      padding: 0 46px;
      .step-label {
        svg {
          color: #0E5676 !important;
          fill: #0E5676 !important;
        }
      }
    }
    .create-modal-actions {
      padding: 10px 16px 20px 16px !important;
      .perv-button-label {
        svg {
          position: relative !important;
          top: 7px !important;
          left: -7px !important;
          //color: rgba(0, 0, 0, 0.87) !important;
          color: #007aaf !important;
          fill: rgb(117, 117, 117) !important;
        }
      }
    }
    .modal-screen-title {
      width: 100%;
      display: inline-block;
      text-align: center;
      font-size: 18px;
      & > * {
        vertical-align: middle;
      }
      svg {
        position: relative;
        top: -2px;
        margin-right: 10px;
      }
    }
    .create-scenario-content-wrapper {
      min-height: 300px;
      .persona-selection {
        text-align: center;
        padding-top: 10px;
        font-size: 18px;
        .personas-wrapper.modal {
          text-align: left;
        }
        .persona-table-wrapper.modal {
          max-height: 30vh;
          overflow-y: auto;
        }
        & > * {
          min-width: 200px;
          vertical-align: top;
          & > *:nth-child(2) {
            padding: 0 10px;
          }
        }
        & > div > span {
          font-size: 18px;
          padding-bottom: 16px;
          display: inline-block;
        }
        .type-selection {
          margin-right: 35px;
          display: inline-block;
          text-align: left;
          font-size: 16px;
        }
        .selected-values {
          display: inline-block;
          text-align: left;
          margin-left: 35px;
          font-size: 16px;
          & > * {
            display: block;
            & > * {
              vertical-align: middle;
            }
          }
        }
      }
      .context-selection {
        & > *:not(.persona-list-wrapper) {
          display: inline-block;
          width: 50%;
          vertical-align: top;
          padding: 10px 32px;
          box-sizing: border-box;
          overflow: hidden;
        }
        .column-item-wrapper:not(.big-and-centered) {
          width: calc(100% - 40px);
          float: right;
          position: relative;
          & > svg {
            position: absolute;
            left: -40px;
            &:not(.no-vertical-align) {
              top: 36px;
            }
          }
          & > div {
            max-width: 90%;
          }
          .toggle {
            width: 220px !important;
            & > div > div > div:first-of-type {
              opacity: 0.5;
            }
          }
        }
        .column-item-wrapper.big-and-centered {
          height: 120px;
          vertical-align: middle;
          text-align: center;
          float: right;
          width: 100%;
          padding-top: 50px;
          box-sizing: border-box;
          & > a {
            text-decoration: none;
            color: inherit;
            & > * {
  
              display: inline-block;
            }
          }
          svg {
            margin-right: 16px;
            position: relative;
            top: 6px;
          }
        }
        .right-control {
          position: absolute;
          right: 20px;
          top: 26px;
          width: 48px;
          height: 48px;
          &.loader {
            right: 20px;
          }
        }
        .summary-item {
          span.section-title {
            font-size: 18px;
            color: black;
            font-weight: 500;
            display: inline-block;
            margin-bottom: 16px;
          }
          span.section-sub-title {
            font-size: 11px;
          }
          .summary-item-icon-left {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 16px;
            position: relative;
            top: 6px;
          }
          .summary-item-icon-right {
            display: inline-block;
            font-size: .5rem;
            margin-left: 16px;
          }
        }
      }
    }}


    .launch-scenarios-wrapper {
        width: 100%;
        height: calc(100% - 70px);
        position: relative;
        .anchor {
          position: relative;
         // right: -25px;
        }
        .launch-scenario-wrapper {
          .app-card {
            width: 206px;
            height: 139px;
            display: inline-block;
            position: relative;
            overflow: hidden;
            .explore-app-button-wrapper {
              padding: 9px 0 !important;
            }
            & > div {
              margin: 0 !important;
              padding: 0 !important;
              width: 100%;
              height: 100%;
            }
            .media-wrapper {
              height: 100%;
              overflow: hidden;
              & > div {
                height: 77%;
              }
              &.register {
                border-bottom: 2px solid whitesmoke;
                & > div {
                  height: 100%;
                  width: 100%;
                  & > div {
                    height: 100%;
                    width: 100%;
                    & > button {
                      height: 100% !important;
                      width: 100%;
                      & > div {
                        & > svg {
                          margin: 0 !important;
                        }
                      }
                    }
                  }
                }
              }
            }
            .card-title {
              position: absolute !important;
              color: #f5f5f5;
              //color: mediumblue;
              width: 100%;
              height: 46px;
              bottom: 0;
              box-sizing: border-box;
              .app-name {
                font-weight: 700;
                font-size: 14px;
                text-align: left;
                position: absolute;
                line-height: 14px;
                top: 16px;
                padding: 0 20px;
                display: inline-block;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                box-sizing: border-box;
              }
              .app-description {
                font-size: 12px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
            .card-actions-wrapper {
              padding: 8px;
              position: absolute !important;
              width: 100%;
              left: 0;
              bottom: -22%;
              box-sizing: border-box;
              display: flex;
              justify-content: flex-start;
              transition: all .5s;
              & > * {
                margin-right: 0 !important;
              }
              button {
                color: whitesmoke !important;
              }
            }
          }
        }
      
        .loader-wrapper {
          width: 100%;
          height: 100%;
          overflow: hidden;
          vertical-align: middle;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      
        .filter-wrapper {
          margin: 16px 0;
          .rev {
            transform: rotate(180deg);
          }
          .sort-button {
            padding-left: 0!important;
            padding-right: 0!important;
            left: -12px;
          }
          .sort-select {
            width: 140px !important;
            margin: 0 !important;
            left: -7px;
            & > div:nth-child(2) > div > div:nth-child(2) {
              padding-right: 10px !important;
            }
            button {
              fill: #3D3D3D !important;
              padding-left: 0 !important;
              text-align: left !important;
              padding-right: 0 !important;
              width: 26px !important;
              right: 16px !important; 
            }
          }
          & > * {
            display: inline-block;
            vertical-align: middle;
            &:not(.actions) {
              margin-right: 15px;
            }
            &.actions {
              float: right;
              position: relative;
              top: -25px;
            }
          }
        }
      }
      
      .launch-scenario-dialog {
        & > div {
          & > div {
            padding: 0 !important;
          }
        }
        .close-button {
          margin-top: 0 !important;
        }
        .inputs {
          padding: 20px;
          margin-top: 50px;
          .label-value {
            span:first-of-type {
              display: inline-block;
              width: 80px;
              color: lightgray;
            }
          }
        }
      }
      
      .launch-scenario-dialog-action {
        margin: .5rem 1rem .5rem 0;
      }
      
      .scenarios-list {
        .scenarios-list-row {
          position: relative;
          height: 70px;
          white-space: nowrap;
          transition: all .3s;
          &:hover {
            cursor: pointer;
          }
          &::before {
            bottom: 0;
            box-shadow: 0 -1px 0 #e0e0e0, 0 0 2px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .24);
            content: '';
            display: block;
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
          }
          &.active {
            height: 470px;
            margin: 21px 0;
            transform: scale(1.03);
            transition: all .3s;
            .expanded-toggle {
              transition: all .3s;
              transform: scaleY(-1);
            }
            .content {
              transition: all .3s;
              height: calc(100% - 70px);
              opacity: 1;
              &:hover {
                cursor: initial;
              }
              & > * {
                opacity: 1;
              }
            }
            .actions-wrapper {
              width: 135px;
              button.hidden-button {
                width: 24px !important;
                margin: 8px !important;
              }
              button.visible-button {
                width: 0 !important;
                margin: 0 !important;
                overflow: hidden !important;
              }
            }
            .title-wrapper {
              width: calc(100% - 64px - 121px - 34px);
              left: -60px;
              top: 10px;
              transition: all .3s;
              .launch-scenario-title {
                transition: all .3s;
              }
              .launch-scenario-app-name {
                transition: all .1s;
                opacity: 0;
              }
            }
            .left-icon-wrapper {
              transition: all .3s;
              top: 112px;
              left: 14px;
              z-index: -1;
            }
          }
          .title-wrapper {
            line-height: 16px;
            width: calc(100% - 64px - 121px);
            overflow: hidden;
            left: 0;
            position: relative;
            transition: all .3s;
            .launch-scenario-title {
              // color: rgba(0, 0, 0, 0.8);
              color: #007aaf;
              font-size: 16px;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: inline-block;
              transition: all .3s;
            }
            .launch-scenario-app-name {
              display: block;
              //color: rgba(0, 0, 0, 0.54);
              color: #007aaf;
              font-size: 14px;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              transition: all .3s;
              transition-delay: .3s;
            }
          }
          & > * {
            display: inline-block;
            vertical-align: middle;
            line-height: 70px;
          }
          .left-icon-wrapper {
            width: 40px;
            height: 40px;
            margin-left: 17px;
            text-align: center;
            border-radius: 20px;
            margin-right: 16px;
            position: relative;
            transition: all .3s;
            top: 0;
            left: 0;
            .left-icon {
              width: 24px;
              height: 24px;
              display: inline-block;
              font-size: 16px;
              position: relative;
              right: -1px;
              i {
                position: absolute;
                top: -3px;
                left: 3px;
                svg {
                  position: absolute;
                  top: -6px;
                  left: -3px;
                  width: 24px;
                  height: 24px;
                  fill: #FFF;
                }
              }
            }
          }
          .content {
            transition: all .3s;
            display: block;
            height: 0;
           // overflow: hidden;
            padding: 17px 32px;
            & > * {
              opacity: 0;
              transition: all .3s;
            }
            .section-title {
              display: block;
              font-size: 18px;
              margin-bottom: 16px;
              line-height: 16px;
              font-weight: 700;
              svg {
                vertical-align: middle;
                margin-right: 12px;
              }
            }
            .app-wrapper {
              width: 240px;
              margin-top: 23px;
              bottom: 0;
              .app-card.small {
                margin-left: 2px;
              }
            }
            .right-side-wrapper {
              display: inline-block;
              max-width: 70%;
              box-sizing: border-box;
              overflow: hidden;
              .description-wrapper {
                margin-top: 20px;
                .description {
                  line-height: 20px;
                  white-space: normal;
                }
              }
            }
            .persona-wrapper, .context-wrapper, .custom-context-wrapper {
              width: 250px;
              line-height: 20px;
              display: inline-block;
              margin-right: 36px;
              vertical-align: top;
              position: relative;
              &.custom-context-wrapper {
                .section-title {
                  margin-bottom: 0;
                }
              }
              .custom-context-table-wrapper {
                & > div:nth-of-type(2) > div:nth-of-type(2) {
                  max-height: 170px !important;
                  overflow-y: auto !important;
                }
                th, tr {
                  max-height: 45px !important;
                  height: 45px !important;
                }
                .custom-context-table {
                  max-height: 250px;
                  overflow-y: auto;
                  .table-body {
                    tr, td {
                      height: 20px !important;
                      &:hover {
                        cursor: pointer;
                      }
                      div {
                        max-height: 50px !important;
                      }
                      input {
                        margin-top: 0 !important;
                      }
                      label {
                       // top: 20px !important;
                      }
                    }
                  }
                }
              }
              .add-custom-context {
                position: absolute;
                right: 0;
                z-index: 1000;
                &.delete button {
                  //background-color: #9C0227 !important;
                  background-color: #007abd !important;
                }
              }
              .persona-name, .persona-id {
                font-size: 14px;
                display: block;
                margin-left: 56px;
              }
              .persona-name {
                font-weight: 700;
              }
              .persona-id {
                line-height: 30px;
                min-height: 30px;
              }
              .section-label {
                width: 100px;
                display: inline-block;
                font-size: 14px;
              }
              .section-value {
                font-size: 14px;
                font-weight: 700;
                display: block;
                margin: 5px 0;
                & > * {
                  vertical-align: middle;
                }
                svg {
                  margin-right: 11px;
                  position: relative;
                  top: -3px;
                }
              }
            }
          }
          .actions-wrapper {
            width: 103px;
           // 
            & > * {
              vertical-align: middle;
            }
            button.hidden-button {
                float: right;
              width: 0 !important;
              overflow: hidden !important;
              margin: 0 !important;
            }
            button {
              padding: 0 !important;
              width: 24px !important;
              height: 24px !important;
              margin-right: 8px !important;
            }
            .expanded-toggle {
              transition: all .3s;
            }
          }
        }
      }