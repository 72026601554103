/* src/Banner.css */

.banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 410px; /* Adjust height as needed */
    background: linear-gradient(to right, #add8e6, #87cefa); /* Light blue gradient */
    padding: 20px;
    color: #333; /* Dark text for better contrast */
  }
  
  .banner-text {
    flex: 1; /* Takes up space on the left */
  }
  
  .banner-image {
    flex: 1; /* Takes up space on the right */
    display: flex;
    justify-content: center; /* Center the image */
  }

  .h1Text {
    font-size: 45px;
    font-weight: 700;
    color:#fff;
  }
  
  