.fade-in {
  @include keyframeMaker(fade-in) {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: fade-in 1s #{bezier()};
}

@keyframes spin {
  0% {transform: rotate(0)}
  100% {transform: rotate(360deg)}
}

.spin {
  animation: spin 3s infinite linear;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 140px;
}
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.imgdiv {
  text-align: center;
  margin-left: 0%;
  margin-top: 13%
}