.data-manager-wrapper {
    margin: 0 !important;
    max-width: 100% !important;
  
    .data-tabs > div:first-of-type {
      height: 57px;
      background-color: white;
      border-bottom: 2px solid whitesmoke;
      width: 100% !important;
      padding: 0 4px;
  
      & > button {
        max-width: 125px;
      }
  
      & > div:nth-child(2) {
        max-width: 125px;
      }
  
      .MuiTabs-flexContainer {
        margin-top: 5px;
  
        button {
          &.Mui-selected {
            span {
              color: #0E5676;
            }
          }
  
          span:first-child {
            font-size: 1.1rem;
            line-height: 1.1rem;
          }
        }
      }
    }
  
    .data-tabs > div:nth-child(2) {
      max-width: 375px;
      padding: 0 4px;
  
      & > div {
        max-width: 125px;
      }
    }
  
    .data-tabs-container {
      padding-right: 10px;
      padding-bottom: 20px;
    }
  }
  .export-wrapper {
    padding: 0 1rem;
    margin-top: 16px;
  
    .controls-wrapper {
      position: relative;
  
      .input-wrapper {
        width: 70%;
        display: inline-block;
        margin-right: 2rem;
        position: relative;
      }
  
      .exporting-status-wrapper {
        margin: 10px 0;
        text-align: center;
      }
    }
  }
  .import-wrapper {
    padding: 3.5rem 2rem 2rem 2rem;
  
    .import-button {
      position: absolute;
      right: 0;
      top: 90px;
  
      & > * {
        margin-left: 10px;
      }
    }
  
    .import-tabs {
      position: relative;
      overflow: hidden;
      box-shadow: 0 -1px 0 #e0e0e0, 0 0 2px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .24);
  
      button {
        position: relative;
        z-index: 1000;
  
        span {
          font-size: 1.1rem;
        }
  
        &.Mui-selected {
          span {
            color: white;
          }
        }
  
        svg {
          position: relative;
          top: 7px;
        }
      }
  
      .MuiTabs-indicator {
        height: 57px;
        z-index: 10;
      }
    }
  
    .import-tabs-container {
      min-height: 600px;
      box-sizing: border-box;
      max-height: 'calc(100vh - 340px)';
      background-color: white;
      overflow: auto;
  
      & > div {
        & > * {
          &:first-child {
            padding-top: 20px;
            display: block;
          }
  
          &:last-child {
            padding-bottom: 10px;
            display: block;
          }
  
          padding: 10px 16px;
        }
  
        &:nth-of-type(2) {
          padding-right: 10px;
          min-height: 600px;
          box-sizing: border-box;
          max-height: 'calc(100vh - 340px)';
          overflow: auto;
        }
      }
    }
  
    .import-field-wrapper {
      textarea {
        max-height: 'calc(100vh - 390px)';
        overflow-y: auto;
      }
    }
  
    .result-wrapper {
      width: 'calc(50% - 1rem)';
      display: inline-block;
      height: 'calc(100vh - 210px)';
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
  
      .import-field-wrapper {
        width: 99.5% !important;
        max-height: 'calc(100vh - 340px)';
  
        & > div {
          overflow: hidden;
  
          & > textarea:nth-child(2) {
            height: 100% !important;
            padding: 10px !important;
            margin: 0 !important;
          }
        }
      }
  
      & > div {
        box-shadow: none !important;
        height: 95%;
  
        & > h2 {
          margin: 0;
          padding: 0;
          color: darkgray;
        }
  
        & > h4 {
          color: darkgray;
          margin: 0;
          font-size: 0.8rem;
        }
      }
    }
  }
  .query-browser-wrapper {
    padding: 0 2rem;
    height: 100%;
  
    .fhir-query-wrapper {
      min-width: 390px;
      max-width: 45vw;
      margin-bottom: 10px;
  
      .input-wrapper {
        width: 73%;
  
        .query {
          label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
            position: absolute;
            top: 2px;
          }
  
          & > div {
            button {
              top: 0;
            }
          }
        }
      }
  
      & > * {
        display: inline-block;
        margin-right: 0.5rem;
      }
  
      button {
        position: relative;
        top: 20px;
        left: 5px;
        margin-top: -10px;
  
        & > span {
          line-height: 55px;
        }
      }
    }
  
    .query-tabs {
      position: relative;
      overflow: hidden;
      box-shadow: 0 -1px 0 #e0e0e0, 0 0 2px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .24);
  
      .MuiTabs-flexContainer {
        position: relative;
        z-index: 1000;
      }
  
      .MuiTabs-indicator {
        height: 57px;
        z-index: 10;
      }
  
      & > div {
        box-sizing: border-box;
  
        &:first-of-type {
          height: 57px;
          background-color: white;
          width: 100% !important;
          z-index: 1000;
  
          &:after {
            //content: " ";
            display: block;
            border-bottom: 1px solid #f5f5f5;
            position: absolute;
            top: 53px;
            left: 0;
            width: 100%;
          }
  
          & > button {
            max-width: 125px;
            z-index: 1000;
  
            svg {
              vertical-align: middle;
              position: relative;
              top: -2px;
            }
          }
  
          & > div:nth-child(2) {
            max-width: 125px;
          }
  
          .MuiTab-wrapper {
            svg {
              position: relative;
              top: 7px;
            }
          }
  
          .Mui-selected {
            color: white;
          }
        }
  
        &:nth-child(2) {
          max-width: 250px;
          position: relative;
          top: -56px;
  
          & > div {
            height: 56px !important;
            top: 0px !important;
            position: absolute !important;
            max-width: 125px;
            z-index: 10;
          }
        }
      }
  
      .tab.active {
        color: white !important;
        transition: all 2s;
      }
    }
  
    .query-tabs-container {
      min-height: 600px;
      box-sizing: border-box;
      background-color: white;
  
      & > div {
        & > * {
          &:first-child {
            padding-top: 16px;
            display: block;
          }
  
          &:last-child {
            padding-bottom: 10px;
            display: block;
          }
  
          padding: 0 16px;
        }
      }
    }
  }
  
  .result-wrapper {
    width: 'calc(50% - 1rem)';
    display: inline-block;
    height: 'calc(100vh - 236px)';
    box-sizing: border-box;
    overflow: hidden;
    min-width: 500px;
  
    .react-json-view {
      padding: 10px;
    }
  
    .result-list-item {
      & > div > div {
        padding: 5px 14px !important;
      }
    }
  
    .title {
      display: inline-block;
      margin-right: 2rem;
    }
  
    .query-size {
      float: right;
  
      & > * {
        color: darkgray;
  
        .number {
          color: #000;
        }
      }
    }
  
    & > div:not(.import-button) {
      height: 'calc(100% + 20px)';
  
      & > div {
        height: 'calc(100vh - 290px)' !important;
        overflow: auto;
        border: 1px solid darkgray;
        border-radius: 5px;
      }
  
      & > h2 {
        margin: 0;
        padding: 0;
        color: darkgray;
      }
    }
  }
  
  .query-result-wrapper {
    .result-item {
      & > *:first-child {
        font-weight: bold;
        display: inline-block;
        margin-right: 5px;
        width: 10rem;
      }
    }
  
    .result-list-item {
      min-height: 53px;
      flex-direction: column;
      justify-content: left;
      align-items: baseline;
    }
  }
  
  .paper-body {
    .result-wrapper {
      width: 100% !important;
  
      & > div {
        & > div {
          height: 94% !important;
        }
      }
    }
  }
  
  .query-result-dialog {
    margin: 0;
    padding: 0;
  
    .close-button {
      color: white !important;
      position: absolute !important;
      top: 0;
      right: 0;
    }
  
    & > * {
      margin: 0 !important;
      padding: 0 !important;
    }
  
    .paper-body {
      padding: 0 !important;
    }
  }
  
  .loader-wrapper-small {
    height: 150px !important;
    text-align: center;
  
    &.top {
      position: absolute;
      top: 200px;
      left: 48%;
    }
  }
  .external-browser-wrapper {
    padding: 0 2rem;
    background-color: white;
    height: 100%;
    .external-query-wrapper, .external-endpoint-wrapper {
      display: inline-block;
      margin: 0 2rem;
      width: 'calc(50% - 8rem)';
    }
    .external-query-wrapper {
      position: relative;
      .clear-query-button.hidden {
        opacity: 0;
      }
      & > *:not(:first-child) {
        position: absolute;
        right: -100px;
        top: 25%;
        & > div:first-of-type {
          margin-right: 1rem;
        }
      }
    }
    .external-endpoint-wrapper {
      & > *:nth-child(2) {
        margin-left: 2rem;
        max-width: 60%;
      }
    }
    .result-wrapper {
      width: 100%;
      display: inline-block;
      height: 'calc(100vh - 236px)';
      box-sizing: border-box;
      & > div {
        padding: 0.5rem 1.5rem;
        height: 95%;
      }
    }
    & > div:nth-child(2) {
      margin-right: 2rem;
    }
  }
  .inputs,.selects{
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
     border-radius: 10px;
     border: 1px solid #2d9fd9;
    //color: #a0d18c;
     //width: 250px;
     height: 35px;
     padding-left: 10px;
    }
    
  .inputs:focus,.selects:focus {
     outline: none;
     border: 1px solid #a0d18c;
     color: #2d9fd9;
  }